class ScrollSmooth {
    constructor(options) {
        this.anchors = document.querySelectorAll('a[href^="#"]');
        this.sections = document.querySelectorAll("section");
        this.headerHeight = document.querySelector("header")?.offsetHeight || 0;
        this.navigationHeight = options.navigationHeight || 0;
    
        this.setupListeners();
    }

    closeBurger() {
        document.getElementById("body").classList.remove("burger-open");
        document.getElementById("menu-button").classList.remove("cross");
    }
  
    setupListeners() {
        this.anchors.forEach((anchor) => {
            anchor.addEventListener("click", (e) => {
                e.preventDefault();

                if (document.getElementById("body").classList.contains("burger-open")) {
                    this.closeBurger();
                }
        
                const targetId = anchor.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);
                if (targetElement) {
                    const offset_navigation =  window.innerWidth >= 1024 ? 0 : this.navigationHeight;
                    const targetElementOffset = window.innerWidth >= 1024
                        ? targetElement.offsetTop
                        : window.pageYOffset + targetElement.getBoundingClientRect().top;
                    const headerHeight = this.headerHeight;
                    const targetOffset = this.getTotalOffsetTop(targetElement);
                    
                    // const targetOffset = targetElementOffset - offset_navigation - this.headerHeight;
        
                    window.scrollTo({
                        top: targetOffset - headerHeight - 40,
                        behavior: "smooth",
                    });
        
                    this.anchors.forEach((a) => {
                        a.parentNode.classList.remove("active");
                    });
        
                    // anchor.parentNode.classList.add("active");
                }
            });
        });
    }

    getTotalOffsetTop(element) {
		var totalOffsetTop = 0;

		while (element) {
			totalOffsetTop += element.offsetTop;
			element = element.offsetParent;
		}

		return totalOffsetTop;
	}
}
export default ScrollSmooth;
  